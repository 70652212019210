import React from "react";
import VirtualBuyerService from "../../../services/VirtualBuyerService";
import ProductImage from "./../Shop/ProductImage";
import ProductTitle from "./../Shop/ProductTitle";

class ResellerOrdersView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { orders: [] };
  }

  componentDidMount() {
    VirtualBuyerService.getOrders().then((orders = []) => {
      this.setState({ orders });
    });
  }
  render() {
    let orders = this.state.orders;
    if (orders && orders.length >= 1) {
      return (
        <div className="table-responsive">
          <table className="table table-cart">
            <thead>
              <tr>
                <th className="product-image" />
                <th>Product</th>
                <th>Attributes</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total Price</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => {
                let {
                  category,
                  type,
                  brand,
                  subtype,
                  attributes,
                  descriptionAttributes,
                } = order.sku;
                let quantity = order.quantity;
                let basePrice = order.basePrice;
                let totalPrice = order.totalPrice;
                let status = order.status;
                let inputStyle;
                if (status === "PENDING") {
                  inputStyle = {
                    backgroundColor: " #0f69f2",
                    borderColor: " #0f69f2",
                  };
                } else if (status === "DELIVERED") {
                  inputStyle = {
                    backgroundColor: " #4CAF50",
                    borderColor: " #4CAF50",
                  };
                }
                let attributesMap = {};
                attributes.forEach((item) => {
                  if (!attributesMap[item.key]) {
                    attributesMap[item.key] = item.value;
                  }
                });
                return (
                  <tr>
                    <td>
                      <ProductImage
                        images={order.sku.images}
                        subtype={order.sku.subtype}
                      />
                    </td>
                    <td className="product-name">
                      <ProductTitle
                        brand={brand}
                        subtype={subtype}
                        descriptionAttributes={descriptionAttributes}
                        attributes={attributesMap}
                      />
                    </td>
                    <td>
                      <ul>
                        {attributes.map((attribute) => (
                          <li>
                            {" "}
                            {attribute.key}:{attribute.value}
                          </li>
                        ))}
                        <li>Category:{category}</li>
                        <li>Type:{type}</li>
                      </ul>
                    </td>
                    <td>{quantity}</td>
                    <td>
                      ₹{basePrice}(
                      {order.sku.pricing.isTaxIncluded === "Y"
                        ? "Including tax "
                        : "Excluding tax "}
                      and
                      {order.sku.pricing.isDeliveryIncluded === "Y"
                        ? " Including Delivery charges "
                        : " Excluding Delivery charges"}
                      )
                    </td>
                    <td>
                      ₹{totalPrice}(
                      {order.sku.pricing.isTaxIncluded === "Y"
                        ? "Including tax "
                        : "Excluding tax "}
                      and
                      {order.sku.pricing.isDeliveryIncluded === "Y"
                        ? " Including Delivery charges "
                        : " Excluding Delivery charges"}
                      )
                    </td>
                    <td>
                      <span
                        className="badge text-uppercase mb-3"
                        style={inputStyle}
                      >
                        {status || "ACTIVE"}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else if (!orders || orders.length === 0) {
      return (
        <section className="slice sct-color-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="text-center">
                  <div className="d-block font-size-3 font-size-md-6 c-gray-light">
                    <i className="fa fa-shopping-bag" aria-hidden="true" />
                  </div>
                  <h5>NO ORDERS</h5>
                  <p className="mt-5 px-5">
                    There are no orders in this project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}
export default ResellerOrdersView;
