import React from "react";
import { connect } from "react-redux";
import {
  addToCart,
  removeFromCart,
  setUserOnStore,
} from "../../../state/actions/cart";
import StorageService from "../../../services/StorageService";
import ModalN from "../../../widgets/modal/ModalN";
import history from "../../../utils/History";
import SignIn from "../../auth/SignIn";
import EditQuantity from "./EditQuantity";
import ProductSingleImage from "../Shop/ProductSingleImage";
import SignUp from "../../auth/SignUp";
import VirtualStoreTitle from "./VirtualStoreTitle";
import EmptyCart from "./EmptyCart";

class VirtualStoreCartView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.setUserOnStore(true);
  };

  componentWillUnmount = () => {
    this.props.setUserOnStore(false);
  };

  updateCart = (product) => {
    this.props.addToCart(product);
  };

  removeFromCart = (skuId) => {
    this.props.removeFromCart(skuId);
  };

  doCheckOut = () => {
    let { cart } = this.props;

    for (let i = 0; i < cart.length; i++) {
      if (cart[i].error) {
        this.props.toastMessage(
          "Some of the products are having errors. Please fix them before submitting.",
          "error",
          5
        );
        return;
      }
    }

    if (StorageService.loggedIn()) {
      console.log("logged in");
      this.onLogin();
    } else {
      console.log("logged out");
      this.setState({ showLoginModal: true });
    }
  };

  hideLoginModal = () => {
    this.setState({ showLoginModal: false });
  };

  showSignUp = () => {
    this.setState({ showSignUp: true });
  };

  onLogin = () => {
    let { code, storeName } = this.props.match.params;
    history.push("/" + code + "/" + storeName + "/checkout");
  };

  calculateTotalPrice = () => {
    let totalPrice = 0;
    let { cart = [] } = this.props;
    cart.forEach((product) => {
      totalPrice += product.totalPrice;
    });
    return totalPrice;
  };

  returnToShop = () => {
    let { code, storeName } = this.props.match.params;
    history.push("/" + code + "/" + storeName);
  };

  showSignUp = () => {
    this.setState({ showSignUp: true, showLoginModal: false });
  };

  hideSignUp = () => {
    this.setState({ showSignUp: false });
  };

  formatPrice = (price) => {
    let currency = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
    return currency;
  };

  render() {
    let { cart, match, storeId } = this.props;
    let { code, storeName } = match.params;

    if (!cart || cart.length === 0) {
      return <EmptyCart code={code} storeName={storeName} />;
    }

    return (
      <React.Fragment>
        <VirtualStoreTitle
          pageName={"Cart"}
          code={code}
          storeName={storeName}
          from={"cart"}
        />
        <div className="container d-flex flex-column justify-content-center align-items-center">
          <div className="card mt-2" style={{ width: "100%" }}>
            <div className="card-body">
              <table className="table-cart">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Attributes</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total Price</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((prod, index) => {
                    let { category, type, brand, subtype, attributes } =
                      prod.sku;

                    let attributesMap = {};

                    attributes.forEach((item) => {
                      if (!attributesMap[item.key]) {
                        attributesMap[item.key] = item.value;
                      }
                    });

                    return (
                      <tr className="cart-item">
                        <td className="mt-1">
                          <div className="d-none d-lg-block">
                            <ProductSingleImage
                              images={prod.sku.images}
                              subtype={prod.sku.subtype}
                              height={"75px"}
                              width={"75px"}
                            />
                          </div>

                          <div className="d-md-none">
                            <ProductSingleImage
                              images={prod.sku.images}
                              subtype={prod.sku.subtype}
                              height={"50px"}
                              width={"40px"}
                            />
                          </div>
                          <div className="mt-1">{brand + " " + subtype}</div>
                        </td>

                        <td className="mt-1">
                          <ul
                            className="d-none d-md-block"
                            style={{ padding: "10px 0" }}
                          >
                            {attributes.map((attribute) => (
                              <li>
                                {" "}
                                <span className="text-muted">
                                  {attribute.key}
                                </span>
                                : <span>{attribute.value}</span>
                              </li>
                            ))}
                            <li>
                              <span className="text-muted">Category</span>:{" "}
                              <span>{category}</span>
                            </li>
                            <li>
                              <span className="text-muted">Type</span>:{" "}
                              <span>{type}</span>
                            </li>
                          </ul>

                          <div
                            className="d-md-none"
                            style={{ padding: "10px 0" }}
                          >
                            {attributes.map((attribute) => (
                              <span> {attribute.value}</span>
                            ))}

                            <span>{" " + category}</span>

                            <span>{" " + type}</span>
                          </div>
                        </td>

                        <td className="mt-1">{"₹ " + prod.priceWithTax}</td>
                        <td className="mt-1">
                          <EditQuantity
                            updateCart={this.updateCart}
                            product={prod}
                            storeId={storeId}
                          />
                        </td>
                        <td className="mt-1">
                          {this.formatPrice(prod.totalPrice) +
                            " (incl. of tax & "}
                          {prod.sku.isDeliveryIncluded === "Y"
                            ? "delivery)"
                            : "excl. of delivery)"}
                        </td>
                        <td>
                          <i
                            onClick={() => this.removeFromCart(prod.sku._id)}
                            style={{ cursor: "pointer" }}
                            className="fa fa-times remove-icon"
                            aria-hidden="true"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div
            style={{ width: "100%" }}
            className="mt-2 d-flex justify-content-end align-items-center"
          >
            <div style={{ width: "100%" }}>
              <a onClick={this.returnToShop} className="link link--style-3">
                <i className="fa fa-angle-left" aria-hidden="true" /> Return to
                shop
              </a>
            </div>
            <div
              className="d-flex justify-content-end align-items-center "
              style={{ width: "100%" }}
            >
              <div className="price-value strong-600" style={{ margin: "5px" }}>
                {"Total Price: " + this.formatPrice(this.calculateTotalPrice())}
              </div>
              <button
                className="btn btn-base-1"
                style={{ margin: "5px" }}
                onClick={this.doCheckOut}
              >
                Proceed to checkout
              </button>
            </div>
          </div>

          {this.state.showLoginModal && (
            <ModalN
              title="Login to continue"
              show={this.state.showLoginModal}
              close={this.hideLoginModal}
            >
              <SignIn from={true} onLogin={this.onLogin} />
              <p>
                <span style={{ color: "#444" }}> Don't have an account?</span>
                <a
                  style={{ color: "#2a6496" }}
                  className="btn-link"
                  onClick={this.showSignUp}
                >
                  {" "}
                  Signup
                </a>
              </p>
            </ModalN>
          )}
          {this.state.showSignUp && (
            <ModalN
              title="Signup to continue"
              show={this.state.showSignUp}
              close={this.hideSignUp}
            >
              <SignUp popUp={true} />
            </ModalN>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    cart: state.cart ? state.cart.items : [],
    storeId: state.cart && state.cart.store ? state.cart.store.storeId : null,
  };
}

function actions(dispatch) {
  return {
    addToCart: (product) => dispatch(addToCart(product)),
    removeFromCart: (skuId) => dispatch(removeFromCart(skuId)),
    setUserOnStore: (userIn) => dispatch(setUserOnStore(userIn)),
  };
}

export default connect(select, actions)(VirtualStoreCartView);
