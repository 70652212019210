/**
 * @flow
 */

'use strict';

const initialState = {
    attributeLabels: {}
};

function sku(state = initialState, action = null) {
    if (action.type === 'RECEIVE_ATTRIBUTE_LABELS') {
        let { attributeLabels } = action.data;
        return {
            attributeLabels
        };
    }
    return state;
}

export default sku;
