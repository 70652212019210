var CITY = "app-city";
var CONTACT = "user-contact";
var L_USER = "L_USER";

function storeLoginUser(user) {
  localStorage.setItem(L_USER, JSON.stringify(user));
}

function updateLoginUser(newUser) {
  var user = getLoginUser();
  user.name = newUser.name;
  user.city = newUser.city;
  user.address = newUser.address;
  storeLoginUser(user);
}

function setCity(city) {
  localStorage.setItem(CITY, JSON.stringify(city));
}

function storeContact(contact) {
  sessionStorage.setItem(CONTACT, JSON.stringify(contact));
}

function updateIsLogAdded() {
  let isUpdated = getIsLogAdded();
  if (!isUpdated) {
    sessionStorage.setItem("isLogAdded", true);
  }
}

function getIsLogAdded() {
  let isUpdated = sessionStorage.getItem("isLogAdded");
  return isUpdated || false;
}

function clearLog() {
  sessionStorage.removeItem("isLogAdded");
}

function addToLocalStorage(key, value) {
  if (key === "store") {
    clearLog();
  }
  localStorage.setItem(key, JSON.stringify(value));
}

function getFromLocalStorage(key) {
  var serviceArea = JSON.parse(localStorage.getItem(key));
  return serviceArea;
}

function getLoginUser() {
  var stored = JSON.parse(localStorage.getItem(L_USER));
  return stored;
}

function getCity() {
  var stored = JSON.parse(localStorage.getItem(CITY));
  return stored;
}

function getContact() {
  var stored = JSON.parse(sessionStorage.getItem(CONTACT));
  return stored;
}

function getToken() {
  var stored = JSON.parse(localStorage.getItem(L_USER));
  return stored ? stored.token : null;
}

function loggedIn() {
  return !!localStorage.getItem(L_USER);
}

function clearStorage() {
  localStorage.removeItem(L_USER);
}

function removeItemsFromLocalStorage(items) {
  items.forEach((item) => localStorage.removeItem(item));
}

export default {
  storeLoginUser: storeLoginUser,
  getLoginUser: getLoginUser,
  updateLoginUser: updateLoginUser,
  getToken: getToken,
  loggedIn: loggedIn,
  storeContact: storeContact,
  clearStorage: clearStorage,
  setCity: setCity,
  getCity: getCity,
  getContact: getContact,
  addToLocalStorage: addToLocalStorage,
  getFromLocalStorage: getFromLocalStorage,
  removeItemsFromLocalStorage,
  updateIsLogAdded: updateIsLogAdded,
  getIsLogAdded: getIsLogAdded,
};
