import React from "react";
import ProductTitle from "./../Shop/ProductTitle";
import ProductAttributes from "./../Shop/ProductAttributes";
import ProductImage from "./../Shop/ProductImage";
import VirtualBuyerService from "./../../../services/VirtualBuyerService";
import StorageService from "./../../../services/StorageService";
import ModalN from "../../../widgets/modal/ModalN";
import QuantityForm from "./QuantityForm";

class ProductAsRequirement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCart: false,
      editIndex: false,
    };
  }

  componentDidMount = () => {
    let serviceArea = StorageService.getFromLocalStorage();
    this.setState({ serviceArea });
  };

  setEditQuantity = () => {
    this.setState({
      editQuantity: true,
      quantity: this.props.requirement.quantity,
    });
  };

  onChangeQuantity = (e) => {
    let { serviceArea = {} } = this.state;

    VirtualBuyerService.calculatePricingForVirtualBuyer(
      e.target.value,
      this.props.requirement.sku._id,
      this.props.serviceArea || serviceArea,
      "VIRTUAL_STORE",
      this.props.storeId
    ).then((response) => {
      if (response.success) {
        this.setState({
          data: response.data,
          success: true,
        });
      } else {
        this.setState({
          data: response.data,
          success: false,
          message: response.msg || null,
        });
      }
    });
    this.setState({ quantity: e.target.value, success: false });
  };

  addToCart = () => {
    if (this.state.quantity && this.state.success) {
      this.props.addToCart(
        this.props.requirement,
        this.state.quantity,
        this.state.data.priceWithTax,
        this.state.data.totalPrice
      );
    }
  };

  updateCart = () => {
    this.props.updateCart(
      this.props.requirement.sku._id,
      this.state.quantity,
      this.state.data.priceWithTax,
      this.state.data.totalPrice
    );
    this.setState({ editQuantity: false });
  };

  onUpdate = (index) => {
    this.props.changeProductQuantity(
      this.props.requirement.sku.subtype,
      index,
      this.props.requirement.sku.brand,
      "quantity",
      this.state.quantity
    );
  };

  showQuantityForm = () => {
    this.setState({ showQuantityForm: true });
  };

  closeQuantityForm = () => {
    this.setState({ showQuantityForm: false, message: " ", options: [] });
  };

  render() {
    let { requirement, fromComponent } = this.props;
    let { sku } = requirement;
    let { brand, subtype, attributes, descriptionAttributes,notes } = sku;
    let { data, showQuantityForm, quantity, editQuantity, success, message } =
      this.state;
    let attributesMap = {};

    attributes.forEach((item) => {
      if (!attributesMap[item.key]) {
        attributesMap[item.key] = item.value;
      }
    });

    return (
      <div className="col-lg-4 mb-2">
        <div
          className="card card-product z-depth-1-top z-depth-2--hover mt-1"
          style={{ height: "360px" }}
        >
          <div className="card-body" style={{ padding: "1rem" }}>
            <ProductTitle
              brand={brand}
              subtype={subtype}
              descriptionAttributes={descriptionAttributes}
              attributes={attributesMap}
            />

            <ProductImage images={sku.images} subtype={sku.subtype} />

            <div className="mt-2">
              <ProductAttributes notes={notes} attributes={attributes} />

              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={this.showQuantityForm}
                  className="btn btn-primary"
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    borderRadius: 30,
                  }}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.showQuantityForm && (
          <ModalN
            title="Product details"
            show={showQuantityForm}
            close={this.closeQuantityForm}
          >
            <QuantityForm
              sku={sku}
              fromComponent={fromComponent}
              data={data}
              requirement={requirement}
              quantity={quantity}
              editQuantity={editQuantity}
              success={success}
              setEditQuantity={this.setEditQuantity}
              onChangeQuantity={this.onChangeQuantity}
              updateCart={this.updateCart}
              addToCart={this.addToCart}
              attributesMap={attributesMap}
              message={message}
            />
          </ModalN>
        )}
      </div>
    );
  }
}
export default ProductAsRequirement;
