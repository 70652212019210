import React from "react";
import StorageService from "../../../services/StorageService";
import history from "../../../utils/History";

class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  calcTotalPrice = (cart = []) => {
    let totalPrice = 0;
    if (cart) {
      cart.map((item) => {
        totalPrice += item.totalPrice;
      });
    }
    return totalPrice;
  };

  formatPrice = (price) => {
    let currency = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
    return currency;
  };

  backToCart = () => {
    let { code, storeName } = this.props;
    history.push("/" + code + "/" + storeName + "/cart");
  };
  render() {
    let cart = this.props.cartItems;
    if (!cart) {
      cart = [];
    }
    let totalPrice = this.calcTotalPrice(cart);

    return (
      <div className="card">
        <div className="card-title">
          <div className="d-flex justify-content-between">
            <div>
              <h3 className="heading heading-3 strong-400 mb-0">Summary</h3>
            </div>

            <div>
              <span
                style={{ cursor: "pointer" }}
                onClick={this.backToCart}
                className="badge badge-md badge-pill bg-blue"
              >
                {cart ? cart.length : 0} items
              </span>
            </div>
          </div>
        </div>

        <div className="card-body">
          <table className="table-cart table-cart-review">
            <thead>
              <tr>
                <th className="product-name">Product</th>
                <th className="product-total text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item) => {
                return (
                  <tr className="cart_item">
                    <td
                      className="product-name"
                      style={{ fontWeight: "500", padding: "5px 0" }}
                    >
                      {item.sku.brand + " | " + item.sku.type + " "}
                      <span className="product-quantity">
                        × {item.quantity}
                      </span>
                    </td>
                    <td
                      className="product-total text-right"
                      style={{ fontWeight: "500", padding: "5px 0" }}
                    >
                      <span>{this.formatPrice(item.totalPrice)}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              <tr className="cart-shipping">
                <th className="text-capitalize">Shipping</th>
                <td className="text-right">
                  <span className="text-italic">Included</span>
                </td>
              </tr>

              <tr className="cart-total">
                <th>
                  <h3 className="heading heading-3 strong-400 mb-2">Total</h3>
                </th>
                <td className="text-right">
                  <strong>
                    <h5> {this.formatPrice(totalPrice)}</h5>
                  </strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}

export default Summary;
