import React from "react";
import { Link } from "react-router-dom";
import StorageService from "../../services/StorageService";
import ServiceAreaPopUp from "../rb/VirtualBuyer/ServiceAreaPopUp";

class DesktopLoggedInHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    let user = StorageService.getLoginUser();
    this.setState({ userPhone: user.phone });
  };

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  render() {
    let { isVirtualStore, storeName, storePhone, exitStore } = this.props;
    let { userPhone } = this.state;
    return (
      <div>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            style={{ borderColor: "#fff", margin: "10px 15px" }}
            onClick={this.toggleMenu}
          >
            <i className="fa fa-bars icon-custom" style={{ color: "#fff" }} />
          </button>

          <a
            className="navbar-brand"
            href="/"
            style={{ margin: "10px 0", paddingLeft: "15px" }}
          >
            <img
              src="/images/logos/sf-logo.png"
              style={{ height: "32px" }}
              alt="Storefronts"
            />
          </a>
        </div>

        {isVirtualStore && (
          <div className="nav navbar-nav">
            <span>
              <h5
                style={{
                  color: "#fff",
                  marginBottom: 0,
                  padding: "15px 30px",
                  fontSize: 22,
                  textAlign: "center",
                }}
              >
                {storeName}
              </h5>
            </span>
          </div>
        )}

        <div
          className={`navbar-collapse collapse ${
            this.state.showMenu ? "in" : ""
          }`}
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link
                style={{
                  fontStyle: "italic",
                  textTransform: "none",
                  paddingBottom: "12px",
                }}
                to="/"
              >
                {"Signed in as " + userPhone}
              </Link>
            </li>
            {isVirtualStore && (
              <li className="dropdown">
                <ServiceAreaPopUp open={this.state.showMenu} />
              </li>
            )}

            {!isVirtualStore && (
              <li>
                <Link style={{ paddingBottom: "12px" }} to="/dashboard">
                  Dashboard
                </Link>
              </li>
            )}

            <li className="dropdown">
              <a href="#">
                <i className="fa fa-phone" style={{ marginRight: "5px" }} />
                {storePhone ? storePhone : "+91 81249 68000"}
              </a>
            </li>

            {isVirtualStore && (
              <li>
                <a style={{ textTransform: "none" }} onClick={exitStore}>
                  <i
                    className="fa fa-sign-out"
                    style={{ marginRight: "5px", fontWeight: 400 }}
                  />
                  Exit this store
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default DesktopLoggedInHeader;
