import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../widgets/headers/Header";
import Home from "./home/Home";
import storageService from "../services/StorageService";
import Toaster from "../widgets/alerts/Toaster";
import GlobalError from "../widgets/alerts/GlobalError";
import Spinner from "../widgets/alerts/Spinner";
import ProductCategoriesContainer from "./home/ProductCategoriesContainer";
import SignUpConfirmation from "./auth/SignUpConfirmation";
import PhoneOTPForm from "./auth/PhoneOTPForm";
import EmailConfirmation from "./auth/EmailConfirmation";
import ResetPassword from "./auth/ResetPassword";
import Terms from "./home/Terms";
import PrivacyPolicy from "./home/PrivacyPolicy";
import OrderPolicy from "./home/OrderPolicy";
import CancellationPolicy from "./home/CancellationPolicy";
import { pushUserToState, pushContactToState } from "../state/actions/login";
import { hydrate } from "../state/actions/navigation";
import Dashboard from "./dashboard/Dashboard";
import SellerSignUpContainer from "./auth/SellerSignUpContainer";
import VirtualStoreCartView from "./rb/VirtualBuyer/VirtualStoreCartView";
import VirtualStore from "./rb/VirtualBuyer/VirtualStore";
import VirtualStoreCheckOut from "./rb/VirtualBuyer/VirtualStoreCheckOut";
import OrderPlaced from "./rb/VirtualBuyer/OrderPlaced";
import SignInView from "./auth/SignInView";
import SignUpView from "./auth/SignUpView";

function requireLogin(nextState, replace) {
  if (!storageService.loggedIn()) {
    replace({
      pathname: "/signin",
      state: { nextPathname: nextState.location.pathname },
    });
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.hydrate();
    let loggedIn = storageService.loggedIn();
    if (loggedIn) {
      let sessionUser = storageService.getLoginUser();
      if (sessionUser) {
        this.props.pushUserToState(sessionUser);
      }
    }
  }

  render() {
    return (
      <div
        className="body-wrap"
        style={{ background: "#eee", height: "100vh" }}
        draggable="false"
      >
        <Header pathname={this.props.location.pathname} />
        <Toaster />
        <GlobalError />
        <Spinner />

        <Switch>
          {/*<Route*/}
          {/*  exact*/}
          {/*  path="/product-categories"*/}
          {/*  component={ProductCategoriesContainer}*/}
          {/*/>*/}
          <Route exact path="/signin" component={SignInView} />
          <Route exact path="/signup" component={SignUpView} />
          <Route
            exact
            path="/seller-signup"
            component={SellerSignUpContainer}
          />

          <Route
            exact
            path="/signup/confirmation/:type"
            component={SignUpConfirmation}
          />
          <Route exact path="/cfph" component={PhoneOTPForm} />
          <Route exact path="/confirm/phone" component={PhoneOTPForm} />
          <Route
            exact
            path="/confirm/email/:key"
            component={EmailConfirmation}
          />
          <Route
            exact
            path="/complete/signup/:key"
            component={EmailConfirmation}
          />
          <Route exact path="/rp/:key" component={ResetPassword} />
          <Route exact path="/terms-and-conditions" component={Terms} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/order-shipping-policy" component={OrderPolicy} />
          <Route
            exact
            path="/cancellation-returns-policy"
            component={CancellationPolicy}
          />

          {/* <Route
            path="/5fbf90bc47236618806d5d16/buyer"
            component={VirtualBuyer}
          /> */}
          <Route
            path="/dashboard"
            component={Dashboard}
            onEnter={requireLogin}
          />

          <Route exact path="/:code" component={VirtualStore} />
          <Route exact path="/:code/:storeName" component={VirtualStore} />
          <Route
            exact
            path="/:code/:storeName/cart"
            component={VirtualStoreCartView}
          />
          <Route
            exact
            path="/:code/:storeName/checkout"
            component={VirtualStoreCheckOut}
          />
          <Route
            exact
            path="/:code/:storeName/order-placed"
            component={OrderPlaced}
          />

          <Route exact path="/" component={Home} />
        </Switch>
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    pushUserToState: (user) => dispatch(pushUserToState(user)),
    pushContactToState: (contact) => dispatch(pushContactToState(contact)),
    hydrate: () => dispatch(hydrate()),
  };
}

export default connect(null, actions)(App);
