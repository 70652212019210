import React from "react";

class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="heading heading-3 strong-400 mb-2">Payment Method</h3>

          <div className="">
            <input
              type="radio"
              name="radioPayment"
              id="radioPayment_2"
              checked="checked"
            />
            <label className="heading-5 ml-1" for="radioPayment_2">
              Cash On Delivery
            </label>
          </div>

          <p className="c-gray-light mt-1 pl-4">
            Pay your order using the most known and secure payment menthod on
            delivery of the products you have ordered.
          </p>
        </div>
      </div>
    );
  }
}

export default PaymentMethod;
