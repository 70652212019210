import React from "react";

export default function() {
  return (
    <section className="slice bg-white">
      <br />

      <div className="wp-section">
        <div className="container">
          <div className="row">
            <h5>Cancellation And Return Policy</h5>

            <b>Can I cancel my order placed on Storefronts?</b>

            <p>
              Yes. You can cancel the order placed on Storefronts, in the case
              that the product has not been shipped by seller at the time of
              cancelling.
            </p>

            <b>Can I return my order after taking delivery?</b>

            <p>
              Storefronts allows return of product in case of the following:
              <br />
              - Product is significantly different from what is described on the
              website.
              <br />- Product is damaged when delivered.
            </p>
            <b>In what cases Storefronts does not accept returns?</b>

            <p>
              - For products like sand, blue metal, bricks &amp; blocks, etc,.
              the quality of which can be visually inspected at the time of
              delivery, if the supervisor/relevant person has visually inspected
              the product at site and has allowed to unload.
              <br />
              - If product is used or altered.
              <br />- If returned without original packaging and accessories.
            </p>
            <b>How to initiate return/cancellation?</b>

            <p>
              - Contact us at our helpline number and provide Order details.
              <br />- Customer Support will confirm the return/cancellation
              request and will inform you about the pickup process.
            </p>

            <b>
              In what scenarios the ordered product is not delivered by
              Storefronts?
            </b>

            <p>
              - Product no longer available or temporarily out of stock.
              <br />
              - Non-serviceable locations, will be confirmed at the time of
              placing the order.
              <br />
              - Restriction on quantity ordered.
              <br />
              - Problems identified by our credit and fraud avoidance
              department.
              <br />
              - Incomplete or inaccurate address.
              <br />- Insufficient/inaccurate customer and delivery information
              on COD orders.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </section>
  );
}
