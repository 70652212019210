import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ChatView from "./../chat/MobileChatView";
import "./Dashboard.css";
import OrdersView from "../orders/OrdersView";
import SettingsView from "../settings/SettingsView";
import OrderSummary from "../orders/OrderSummary";
import VirtualStoresView from "../rb/VirtualBuyer/VirtualStoresView";
import ResellerOrdersView from "../rb/VirtualBuyer/ResellerOrdersView";

class BuyerMainContent extends React.Component {
  render() {
    let { smallScreen, leftNav, contentHeight, user } = this.props;
    if (smallScreen) {
      return (
        <div className="row">
          <div className="col-md-12">
            <div
              className="col-xs-10 col-xs-offset-1 main-content"
              style={{ padding: "2px" }}
            >
              <div
                className="mc"
                style={{ height: contentHeight - 45, overflowY: "auto" }}
                id="main-layout"
              >
                <Switch>
                  <Route
                    path="/dashboard/stores"
                    component={VirtualStoresView}
                  />

                  <Route path="/dashboard/chat" component={ChatView} />

                  <Route path="/dashboard/orders" component={OrdersView} />

                  <Route path="/dashboard/settings" component={SettingsView} />

                  <Route
                    path="/dashboard/orders/:orderId"
                    component={OrderSummary}
                  />

                  <Redirect from="/" to="/dashboard/stores" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-md-2">{leftNav}</div>
          <div className="col-md-10">
            <div className="main-content">
              <div
                className="mc"
                style={{
                  height: "calc(100vh - 50px)",
                  overflowY: "auto",
                }}
                id="main-layout"
              >
                <div style={{ padding: "20px" }}>
                  <Switch>
                    <Route path="/dashboard/chat" component={ChatView} />

                    <Route
                      path="/dashboard/orders"
                      component={ResellerOrdersView}
                    />

                    <Route
                      path="/dashboard/stores"
                      component={VirtualStoresView}
                    />

                    <Route
                      path="/dashboard/settings"
                      component={SettingsView}
                    />

                    <Route
                      path="/dashboard/orders/:orderId"
                      component={OrderSummary}
                    />

                    <Redirect from="/" to="/dashboard/stores" />
                  </Switch>
                </div>
              </div>
              {/*<div className="col-md-3">*/}
              {/*  <h5 className="dashboard-title" style={{ marginTop: "70px" }}>*/}
              {/*    Talk to us*/}
              {/*  </h5>*/}

              {/*  <div*/}
              {/*    className="chat-content"*/}
              {/*    style={{*/}
              {/*      boxShadow:*/}
              {/*        "0 1px 1px 0 rgba(0,0,0,.06),0 2px 5px 0 rgba(0,0,0,.2)",*/}
              {/*      marginRight: "30px"*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <ChatContainer*/}
              {/*      convoStyle={{*/}
              {/*        padding: "10px",*/}
              {/*        overflowY: "auto",*/}
              {/*        height: contentHeight - 285,*/}
              {/*        marginBottom: "5px"*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default BuyerMainContent;
