import React from "react";

class ShippingAddressesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { shippingAddresses } = this.props;

    return (
      <React.Fragment>
        {shippingAddresses.map((address, index) => {
          return (
            <div
              className={
                "card mt-1 p-2" +
                (this.props.selectedShippingAddressId &&
                this.props.selectedShippingAddressId === address._id
                  ? " border-selected"
                  : " ")
              }
              style={{ cursor: "pointer" }}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="px-4 py-4">
                    <div className="d-flex">
                      <div>
                        <input
                          type="radio"
                          id="radioPayment_2"
                          onClick={() =>
                            this.props.setShippingAddress(address._id)
                          }
                          checked={
                            this.props.selectedShippingAddressId &&
                            this.props.selectedShippingAddressId === address._id
                          }
                        />
                      </div>
                      <div className="ml-2">
                        <p className="c-gray-light">
                          {address.addressLine1
                            ? address.addressLine1 + ", "
                            : null}
                          {address.addressLine2
                            ? address.addressLine2 + ", "
                            : null}
                          {address.locality ? address.locality + ", " : null}
                          {address.zone ? address.zone + ", " : null}
                          {address.district ? address.district + ", " : null}
                          {address.state ? address.state + " - " : null}
                          {address.pinCode ? address.pinCode + "." : null}
                        </p>

                        <a
                          onClick={() =>
                            this.props.editShippingAddress(index, address._id)
                          }
                          className="btn btn-sm btn-link"
                        >
                          <i className="fa fa-pencil-square-o" />
                          Edit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default ShippingAddressesView;
