/**
 * @flow
 */

"use strict";

export function addToCart(product) {
  return {
    type: "ADD_TO_CART",
    product,
  };
}

export function removeFromCart(skuId) {
  return {
    type: "REMOVE_FROM_CART",
    skuId,
  };
}

export function clearCart() {
  return {
    type: "CLEAR_CART",
  };
}

export function addServiceArea(serviceArea) {
  return {
    type: "ADD_SERVICE_AREA",
    serviceArea,
  };
}

export function resetCart() {
  return { type: "RESET_CART" };
}

export function setStore(store) {
  return {
    type: "SET_STORE",
    store,
  };
}

export function setUserOnStore(userIn) {
  return {
    type: "SET_USER_ON_STORE",
    userIn,
  };
}
