import React from "react";
import history from "../../../utils/History";

class OrderPlaced extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  returnToShop = () => {
    let { code, storeName } = this.props.match.params;
    history.push("/" + code + "/" + storeName);
  };

  render() {
    return (
      <div
        style={{ height: "calc(100vh - 100px)" }}
        className="container d-flex justify-content-center align-items-center"
      >
        <div className="row d-flex justify-content-center">
          <div className="col-md-7">
            <div className="card">
              <div className="card-body py-5 text-center">
                <div className="icon-block icon-block--style-2-v1 text-center">
                  <div className="block-icon circle bg-green">
                    <i className="ion-checkmark-round" />
                  </div>
                  <h2 className="heading heading-3 strong-500">
                    Your order has been placed.
                  </h2>
                  <p className="mt-3 px-5">
                    Thank you for your purchase. Your orders are placed under
                    Pending status until it is reviewed and shipped. The process
                    could take between 2-4 hours.
                  </p>
                  <div>
                    <a
                      onClick={this.returnToShop}
                      className="link link--style-3"
                    >
                      <i className="fa fa-angle-left" aria-hidden="true" />{" "}
                      Return to shop
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderPlaced;
