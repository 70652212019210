import React from "react";
import SignUp from "./SignUp";
import SignUpView from "./SignUpView";

class SellerSignUpContainer extends React.Component {
  render() {
    return <SignUpView seller />;
  }
}
export default SellerSignUpContainer;
