import React from "react";
import VirtualBuyerService from "../../../services/VirtualBuyerService";
import ValidationUtils from "../../../utils/ValidationUtils";
import Error from "../../../widgets/alerts/Error";
import Select from "react-select";
import VirtualStore from "./VirtualStore";
import LocationFinder from "./LocationFinder";
class ServiceAreaFormBuyer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: true, showStore: false };
  }

  componentDidMount() {
    let { storeId } = this.props;
    if (storeId) {
      this.getStateOptions(storeId);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    let { storeId } = nextProps;

    if (storeId && storeId != this.props.storeId) {
      this.getStateOptions(storeId);
    }
  };

  getStateOptions = (storeId) => {
    VirtualBuyerService.getVirtualStoreStateOptions(storeId).then(
      (stateOptions) => {
        this.setState({
          stateOptions: stateOptions.map((l) => ({
            value: l,
            label: l,
          })),
        });
      }
    );
  };

  getDistrictOptions = (state) => {
    let { storeId } = this.props;
    VirtualBuyerService.getVirtualStoreDistrictOptions(storeId, state).then(
      (districtOptions) => {
        this.setState({
          districtOptions: districtOptions.map((l) => ({
            value: l,
            label: l,
          })),
        });
      }
    );
  };

  onChangeState = (option, getOptions) => {
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions) {
      this.getDistrictOptions(option.value);
    }
    let { serviceArea = {} } = this.state;
    serviceArea["state"] = option;
    serviceArea.district = null;
    serviceArea.zone = null;
    serviceArea.locality = null;
    this.setState({ serviceArea: { ...serviceArea } });
  };

  onChangeDistrict = (option, getOptions) => {
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions) {
      this.getZones(option.value);
    }
    let { serviceArea = {} } = this.state;
    serviceArea["district"] = option;
    serviceArea.zone = null;
    serviceArea.locality = null;
    this.setState({ serviceArea: { ...serviceArea } });
  };

  onChangeZone = (option, getOptions) => {
    getOptions === false ? (getOptions = false) : (getOptions = true);
    if (getOptions) {
      this.getlocality(option.value);
    }
    let { serviceArea = {} } = this.state;
    serviceArea["zone"] = option;
    serviceArea.locality = null;
    this.setState({
      serviceArea: { ...serviceArea },
    });
  };

  onChangeLocality = (options) => {
    let { serviceArea = {} } = this.state;
    serviceArea["locality"] = options;
    this.setState({
      serviceArea: { ...serviceArea },
    });
  };

  getZones = (district) => {
    let { storeId } = this.props;
    VirtualBuyerService.getVirtualStoreZoneOptions(district, storeId).then(
      (zoneOptions) => {
        this.setState({ zoneOptions });
      }
    );
  };

  getlocality = (zone) => {
    let { storeId } = this.props;
    VirtualBuyerService.getVirtualStoreLocalityOptions(zone, storeId).then(
      (localityOptions) => {
        this.setState({ localityOptions });
      }
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    var errorMessage = ValidationUtils.validateVirtualBuyerForm(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }

    let data = JSON.parse(JSON.stringify(this.state));
    let serviceArea = data.serviceArea;
    serviceArea.state = serviceArea.state ? serviceArea.state.value : null;
    serviceArea.district = serviceArea.district
      ? serviceArea.district.value
      : null;
    serviceArea.zone = serviceArea.zone ? serviceArea.zone.value : null;
    serviceArea.locality = serviceArea.locality
      ? serviceArea.locality.value
      : null;

    this.props.onAddServiceArea(serviceArea);
  };

  render() {
    let { serviceArea = {}, showStore, buyerData } = this.state;
    let { storeId } = this.props;
    if (showStore) {
      return <VirtualStore buyerData={buyerData} from={true} />;
    }
    return (
      <div className="form-body">
        <form className="form-default" onSubmit={this.submitForm}>
          <Error message={this.state.error} style={{ margin: "10px" }} />
          <LocationFinder
            onChangeState={this.onChangeState}
            onChangeDistrict={this.onChangeDistrict}
            onChangeZone={this.onChangeZone}
            onChangeLocality={this.onChangeLocality}
            storeId={storeId}
          />
          <div className="d-flex mb-1 flex-column">
            <Select
              className="flex-fill mb-1"
              placeholder="State"
              options={this.state.stateOptions}
              value={serviceArea.state}
              onChange={this.onChangeState}
              isMulti={false}
            />
            {serviceArea.state && (
              <Select
                className="flex-fill mb-1"
                placeholder="District"
                options={this.state.districtOptions}
                value={serviceArea.district}
                onChange={this.onChangeDistrict}
                isMulti={false}
              />
            )}

            {serviceArea.district && (
              <Select
                className="flex-fill mb-1"
                placeholder="Zone"
                options={this.state.zoneOptions}
                value={serviceArea.zone}
                onChange={this.onChangeZone}
                // isMulti={false}
              />
            )}

            {serviceArea.zone && (
              <Select
                className="flex-fill"
                placeholder="locality"
                options={this.state.localityOptions}
                value={serviceArea.locality}
                onChange={this.onChangeLocality}
                // isMulti
              />
            )}
          </div>

          <div className="mt-2 d-flex justify-content-end">
            <button type="submit" className="btn btn-success">
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ServiceAreaFormBuyer;
