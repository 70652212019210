import React from "react";
import { connect } from "react-redux";
import AnonymousHeader from "./AnonymousHeader";
import LoggedInHeader from "../../components/headers/LoggedInHeader";

class Header extends React.Component {
  render() {
    let loggedIn = !!(this.props.user && this.props.user.isLoggedIn);
    return (
      <div id="divHeaderWrapper">
        <header className="header-standard-2">
          <div
            className="navbar navbar-wp navbar-arrow mega-nav"
            role="navigation"
            style={{
              borderBottom: "0px",
              background: this.props.pathname === "/" ? "#374B73" : "#374B73",
            }}
          >
            {!loggedIn && (
              <AnonymousHeader
                storeName={this.props.storeName}
                storeId={this.props.storeId}
                storePhone={this.props.storePhone}
                pathname={this.props.pathname}
              />
            )}
            {loggedIn && (
              <LoggedInHeader
                storeName={this.props.storeName}
                storeId={this.props.storeId}
                storePhone={this.props.storePhone}
                pathname={this.props.pathname}
              />
            )}
          </div>
        </header>
      </div>
    );
  }
}

function data(state) {
  return {
    user: state.user,
    storeName:
      state.cart && state.cart.store ? state.cart.store.storeName : null,
    storeId: state.cart && state.cart.store ? state.cart.store.storeId : null,
    storePhone:
      state.cart && state.cart.store ? state.cart.store.storePhone : null,
  };
}

export default connect(data)(Header);
