import React from "react";

export default function () {
  return (
    <div>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="col">
                <h4>Contact us</h4>
                <ul>
                  <li>
                    #1, Third Floor, 4th Main Road, <br />
                    Kasthuribai Nagar, Adyar,
                    <br />
                    Chennai - 600020
                  </li>
                  <li>Phone: +91 81249 68000</li>
                  <li>
                    Email:{" "}
                    <a href="mailto:hello@buildstreet.com" title="Email Us">
                      hello@buildstreet.com
                    </a>
                  </li>
                </ul>
              </div>
              <br />

              {/* <div className="col col-social-icons">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/Buildstreet">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.buildstreet.com/blog/">Our Blog</a>
                  </li>
                </ul>
              </div> */}
            </div>

            <div className="col-md-4">
              <div className="col">
                <h4>Policies</h4>
                <ul>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/order-shipping-policy">
                      Order And Shipping Policy
                    </a>
                  </li>
                  <li>
                    <a href="/cancellation-returns-policy">
                      Cancellation And Returns Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-5">
              <div className="col">
                <h4>About us</h4>

                <p className="no-margin">
                  Storefronts is your favourite retailer's online shop. We
                  further help to increase the retailer's product range and
                  ensure better prices and delivery to his customers. Buy ONLINE
                  at the convenience of OFFLINE starting today
                </p>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-lg-12">
              <ul>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    CEMENT:
                  </a>
                  <a href="/">India Cements</a>|<a href="/">Coromandel</a> |
                  <a href="/">Ramco Cement</a> |<a href="/">Dalmia Cement</a> |
                  <a href="/">Zuari Cement</a> |<a href="/">JSW Cement</a> |
                  <a href="/">Penna</a> |<a href="/">Priya Cement</a> |
                  <a href="/">Portland Cement</a> |
                  <a href="/">53 Grade/PPC/PSC</a> |
                  <a href="/">Cement Price in Chennai and Coimbatore</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    {" "}
                    TMT RODS:
                  </a>
                  <a href="/">Tata Tiscon</a> |<a href="/">iSteel</a> |
                  <a href="/">Agni TMT</a> |<a href="/">Kamatchi TMT</a> |
                  <a href="/">Suryadev TMT</a> |<a href="/">ARS TMT</a> |
                  <a href="/">Steel Prices in Chennai and Coimbatore</a> |
                  <a href="/">Rebars</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    SAND:
                  </a>
                  <a href="/">River Sand</a> |<a href="/">Trichy river sand</a>{" "}
                  |<a href="/">Graded Sand</a> |<a href="/">Ungraded sand</a>
                </li>
                <li>
                  <a href="/">BRICKS &amp; BLOCKS:</a>
                  <a href="/">Bricks</a> |<a href="/">Paver Block</a> |
                  <a href="/">Interlocking blocks</a> |
                  <a href="/">Hollow Block</a> |<a href="/">AAC Blocks</a> |
                  <a href="/">CLC Blocks</a> |<a href="/">Renacon</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    PLUMBING AND BATHROOM FITTINGS:
                  </a>
                  <a href="/">Jaguar</a> |<a href="/">Metro</a> |
                  <a href="/">Parryware</a> |<a href="/">Hindware</a> |
                  <a href="/">Cera</a> |<a href="/">Somany</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    TILES:
                  </a>
                  <a href="/">Floor Tiles</a> |<a href="/">Kajaria Tiles</a> |
                  <a href="/">Johnson Tiles</a> |<a href="/">Somany Tiles</a> |
                  <a href="/">RAK Tiles</a> |<a href="/">KAG Tiles</a> |
                  <a href="/">Vitrified Tiles</a> |<a href="/">Ceramic Tiles</a>{" "}
                  |<a href="/">Terracotta Tiles</a> |<a href="/">Cera Tiles</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    FLOORING AND COUNTER-TOPS:
                  </a>
                  <a href="/">Marble Flooring</a> |
                  <a href="/">Granite Flooring</a> |
                  <a href="/">Italian Marble</a> |
                  <a href="/">Granite Counter Tops</a> |
                  <a href="/">Wooden Flooring</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    POWER SOLUTIONS:
                  </a>
                  <a href="/">Solar Water Heater</a> |
                  <a href="/">Sukam Inverter</a> |
                  <a href="/">Luminous Inverter</a> |<a href="/">UPS</a> |
                  <a href="/">V-Guard Water Heater</a> |
                  <a href="/">Venus Water Heater</a> |
                  <a href="/">Racold Water Heater</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    PAINT:
                  </a>
                  <a href="/">Asian Paints</a> |<a href="/">Dulux Paints</a> |
                  <a href="/">Apex</a> |<a href="/">Tractor</a> |
                  <a href="/">Exterior Emulsion</a> |<a href="/">Royale</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    DOORS AND WINDOWS:
                  </a>
                  <a href="/">UPVC Doors</a> |<a href="/">Teak Doors</a> |
                  <a href="/">Door Designs</a> |<a href="/">Flush Doors</a> |
                  <a href="/">PVC Doors</a> |<a href="/">Teak Wood Doors</a> |
                  <a href="/">Aluminium Windows</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    PLYWOOD AND LAMINATES:
                  </a>
                  <a href="/">Merino Laminates</a> |
                  <a href="/">Greenlam Laminates</a> |
                  <a href="/">Century Plywood</a> |
                  <a href="/">Marine Plywood</a> |<a href="/">Sharon Plywood</a>{" "}
                  |<a href="/">MDF</a> |
                  <a href="/">Plywood price in Chennai and Coimbatore</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    MODULAR KITCHEN AND ACCESSORIES:
                  </a>
                  <a href="/">Sleek Modular Kitchen</a> |<a href="/">Sleek</a> |
                  <a href="/">Hettich</a> |<a href="/">Kaff</a> |
                  <a href="/">Kutchina</a> |<a href="/">Kitchen Sinks</a> |
                  <a href="/">Nirali Sinks</a> |<a href="/">Faber Chimney</a> |
                  <a href="/">Chimneys</a> |<a href="/">Glass Top Stove</a>
                </li>
                <li>
                  <a href="/" style={{ marginRight: "10px" }}>
                    ELECTRICALS:
                  </a>
                  <a href="/">Modular Switches</a> |
                  <a href="/">Electrical Switches</a> |
                  <a href="/">Legrand Switches</a> |
                  <a href="/">Anchor Switches</a> |
                  <a href="/">Havell Switches</a> |
                  <a href="/">Crabtree Switches</a> |
                  <a href="/">Electrical Fittings</a> |
                  <a href="/">Circuit Breakers</a> |<a href="/">RCCBs</a> |
                  <a href="/">MCBs</a> |<a href="/">Electrical Wiring</a>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-12 copyright">
              {new Date().getFullYear()} © Buildstreet Technologies Pvt Ltd. All
              rights reserved.
              <a href="/terms-and-conditions"> Terms and conditions</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
