import React from "react";
import VirtualBuyerService from "../../../services/VirtualBuyerService";
import ValidationUtils from "../../../utils/ValidationUtils";
import Error from "../../../widgets/alerts/Error";

class ShippingAddressesForm extends React.Component {
  constructor(props) {
    super(props);

    if (props.shippingAddress) {
      this.state = { ...props.shippingAddress };
    } else if (props.serviceArea) {
      let sa = props.serviceArea;
      this.state = {
        locality: sa.locality,
        zone: sa.zone,
        district: sa.district,
        state: sa.state || "Tamil Nadu",
      };
    } else {
      this.state = {};
    }
  }

  onChangeAddressLine1 = (e) => {
    this.setState({ addressLine1: e.target.value });
  };

  onChangeAddressLine2 = (e) => {
    this.setState({ addressLine2: e.target.value });
  };

  onChangeCity = (e) => {
    this.setState({ city: e.target.value });
  };

  onChangePincode = (e) => {
    this.setState({ pinCode: e.target.value });
  };

  submitForm = (e) => {
    e.preventDefault();
    var errorMessage = ValidationUtils.validateShippingAddress(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    let data = {};
    data.addressLine1 = this.state.addressLine1 || null;
    data.addressLine2 = this.state.addressLine2 || null;
    data.district = this.state.district || null;
    data.state = this.state.state || "Tamil Nadu";
    data.zone = this.state.zone || null;
    data.locality = this.state.locality || null;
    data.pinCode = this.state.pinCode || null;
    if (this.props.edit) {
      VirtualBuyerService.editShippingAddress(
        data,
        this.props.shippingAddressId
      ).then((response) => {
        if (response.success) {
          this.props.closeEditShippingAddress();
        }
      });
    } else {
      VirtualBuyerService.addShippingAddress(data).then((response) => {
        if (response.success) {
          this.props.closeAddShippingAddress();
        }
      });
    }
  };

  render() {
    let {
      addressLine1,
      addressLine2,
      locality,
      zone,
      district,
      pinCode,
      state,
    } = this.state;
    return (
      <div className="card mt-4">
        <div className="card-body">
          <form onSubmit={this.submitForm}>
            <Error message={this.state.error} style={{ margin: "10px" }} />
            <div className="form-group">
              <label>Address line 1*</label>
              <input
                className="form-control form-control-lg"
                value={addressLine1}
                onChange={this.onChangeAddressLine1}
              />
            </div>
            <div className="form-group">
              <label>Address line 2</label>
              <input
                className="form-control form-control-lg"
                value={addressLine2}
                onChange={this.onChangeAddressLine2}
              />
            </div>

            <div className="form-group">
              <label>Locality</label>
              <div className="text-capitalize">{locality.toLowerCase()}</div>
            </div>

            <div className="form-group">
              <label>Zone</label>
              <div className="text-capitalize">{zone.toLowerCase()}</div>
            </div>

            <div className="form-group">
              <label>District</label>
              <div className="text-capitalize">{district.toLowerCase()}</div>
            </div>

            <div className="form-group">
              <label>State</label>
              <div className="text-capitalize">{state.toLowerCase()}</div>
            </div>

            <div className="form-group">
              <label>Pincode*</label>
              <input
                className="form-control form-control-lg"
                value={pinCode}
                onChange={this.onChangePincode}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button type="submit" className="btn btn-base-1 ">
                {this.props.edit ? "Edit" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default ShippingAddressesForm;
